<template>
  <el-table
    :data="tableData">
    <!-- 排序 -->
    <el-table-column
      prop="sort"
      label="排序"
      min-width="50"
      align="center" />
    <!-- 问题 -->
    <el-table-column
      prop="question"
      label="问题"
      min-width="200"
      align="center" />
    <!-- 答覆 -->
    <el-table-column
      prop="content"
      label="答覆"
      min-width="200"
      align="center">
      <template slot-scope="scope">
        <div class="table-richtext-col" v-html="scope.row.content"></div>
      </template>
    </el-table-column>
    <!-- 状态 -->
    <el-table-column
      label="状态"
      fixed="right"
      min-width="100"
      align="center">
      <template slot-scope="scope">
        <el-switch
          @change="handleChangeState($event, scope.row)"
          :disabled="!editable"
          v-model="scope.row.is_visible"
          active-value="1"
          inactive-value="0" />
        {{ scope.row.is_visible === "1" ? "上架" : "下架" }}
      </template>
    </el-table-column>
    <!-- 操作 -->
    <el-table-column
      label="操作"
      fixed="right"
      min-width="100"
      align="center">
      <template slot-scope="scope">
        <el-button-group>
          <el-tooltip
            effect="dark"
            content="修改"
            placement="top">
            <el-button
              :disabled="!editable"
              type="success"
              icon="el-icon-edit"
              @click="handleEdit(scope.row)" />
          </el-tooltip>
          <el-tooltip
            effect="dark"
            content="刪除"
            placement="top">
            <el-button
              :disabled="!excision"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)" />
          </el-tooltip>
        </el-button-group>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { changeFAQState } from '@/api/admin/help'

export default {
  props: {
    tableData: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    },
    excision: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handleChangeState ($event, row) {
      this.handleEditable(false)
      const status = $event
      changeFAQState(row.id, status)
        .then(res => {
          switch (res.status) {
            case resStatus.OK:
              this.$emit('handleRefresh')
              break
          }
          if (res.message.length !== 0) {
            this.$message(res.message)
          }
          this.handleEditable(true)
        })
        .catch(err => {
          this.$message.error(err)
          this.handleEditable(true)
        })
    },
    handleEditable (boo) {
      this.$emit('handleEditable', boo)
    },
    handleEdit (row) {
      // this.$emit('handleEditData', row)
      this.$router.push({
        name: 'AdminHelpFAQEdit',
        params: {
          id: row.id
        }
      })
    },
    handleDelete (row) {
      this.$emit('handleDelete', row.id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-richtext-col{
    max-height: 140px;
    overflow-y: scroll;
    transform: scale(0.85);
  }
</style>
<style lang="scss">
  .table-richtext-col{
    p {
      img {max-width: 100%;}
    }
  }
</style>
