<template>
  <el-card
    shadow="never"
    :body-style="cardBodyStyle">
    <div class="left-container">
      <el-button
        type="primary"
        @click="handleAddFAQ">
        新增
      </el-button>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    search: {
      type: String,
      require: true
    }
  },
  computed: {
    cardBodyStyle: {
      get () {
        return {
          display: 'flex',
          'justify-content': 'flex-start',
          'align-items': 'center'
        }
      }
    }
  },
  methods: {
    handleAddFAQ () {
      // this.$emit('handlAddVisible', true)
      this.$router.push('FAQ/add')
    }
  }
}
</script>
