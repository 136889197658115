<template>
  <div>
    <div v-if="isEditable">
      <router-view />
    </div>
    <div v-else>
      <help-head />
      <help-table
        :tableData="pageData.data"
        :editable="editable"
        :excision="excision"
        @handleDelete="handleDelete"
        @handleRefresh="handleRefresh" />
      <help-pagination
        :query="query"
        :pageData="pageData"
        @handleQuery="handleQuery"
        @handleRefresh="handleRefresh" />
      <!-- Dialogs -->
      <el-dialog
        title="刪除提示"
        :visible.sync="centerDialogVisible"
        width="30%"
        center>
        <span>是否确认删除？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="checkDelete">确 定</el-button>
        </span>
      </el-dialog>
      <!-- <add-dialog
        :visible="addVisible"
        @handleVisible="handleAddVisible"
        @handleRefresh="handleRefresh" />
      <edit-dialog
        :visible="editVisible"
        :dialogData="editData"
        @handleVisible="handleEditVisible"
        @handleRefresh="handleRefresh" /> -->
    </div>
  </div>
</template>

<script>
import { forIn, has } from 'lodash'
import resStatus from '@/constants/resStatus'
import { getFAQList, deleteFAQ } from '@/api/admin/help'
import { adminLogout } from '@/utils/admin'

import HelpHead from './HelpHead'
import HelpTable from './HelpTable'
import HelpPagination from '@/components/admin/pagination'
// import addForm from './Add/index'
// import AddDialog from './AddDialog'
// import EditDialog from './EditDialog'

export default {
  components: {
    HelpHead,
    HelpTable,
    HelpPagination
    // addForm
    // AddDialog,
    // EditDialog
  },
  data () {
    return {
      centerDialogVisible: false,
      query: {
        page: 1,
        pageSize: 10,
        search: ''
      },
      editable: true,
      excision: true,
      pageData: {
        data: []
      },
      isEditable: false,
      deleteId: ''
      // for add dialog
      // addVisible: false,
      // for edit dialog
      // editVisible: false,
      // editData: {}
    }
  },
  methods: {
    init () {
      const pageName = this.$route.name
      switch (pageName) {
        case 'AdminHelpFAQAdd':
        case 'AdminHelpFAQEdit':
          this.isEditable = true
          break
        default:
          this.isEditable = false
          getFAQList(this.query).then(res => {
            switch (res.status) {
              case resStatus.OK:
                this.pageData = res
                break
              default:
                adminLogout()
                this.$message.error(res.message)
            }
          }).catch(err => {
            adminLogout()
            this.$message.error(err)
          })
          break
      }
    },
    handleQuery (obj) {
      forIn(obj, (val, key) => {
        if (has(this.query, key)) {
          this.query[key] = val
        }
      })
    },
    handleEditable (boo) {
      this.editable = boo
    },
    // handleAddVisible (boo) {
    //   this.addVisible = boo
    // },
    // handleEditVisible (boo) {
    //   this.editVisible = boo
    // },
    // handleEditData (data) {
    //   this.editData = data
    //   this.handleEditVisible(true)
    // },
    handleDelete (id) {
      this.centerDialogVisible = true
      this.deleteId = id
    },
    checkDelete () {
      this.centerDialogVisible = false
      deleteFAQ(this.deleteId).then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.handleRefresh()
            break
          default:
            adminLogout()
            this.$message.error(res.message)
        }
      }).catch(err => {
        adminLogout()
        this.$message.error(err)
      })
    },
    handleRefresh () {
      // this.query.page = 1
      this.init()
    }
  },
  created () {
    this.init()
  },
  watch: {
    $route (to, from) {
      this.init()
    }
  }
}
</script>
